/*eslint-disable*/
export const APP_CONFIG = {
  serverUrl: 'https://musotic-v2-44215-staging.botics.co',
  apiServerUrl: 'https://musotic-37073-staging.botics.co/api/v2',
  apiServerAppleJsSignUpCallback:
    'https://musotic-37073-staging.botics.co/api/v2/apple/login-callback/',
  appleJsUrl:
    'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
  appleMusicKitJsUrl: 'https://js-cdn.music.apple.com/musickit/v3/musickit.js',
};
